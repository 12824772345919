/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/naming-convention */

import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { API } from '../constants';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';

import { getFirestore, collection, doc, DocumentData, onSnapshot, getDoc } from '@firebase/firestore';
import { getAuth } from '@firebase/auth';
import { AlertController } from '@ionic/angular';

import { CurbUser } from '../app.models'
import { WithId } from '../types';

@Injectable({
	providedIn: 'root'
})
export class ConnectionsService {
	public uid: string;
	public myConnections = [];
	myConnectionsSub;

	connectionsDetails: any = {};
	hasPopulatedConnections = false;

	public seeMoreUsersClicked = 0;
	public totalUserInvites = 0;
	public lastUserInvite;

	public inviteLimit;
	private maxSeeMore = 3;
	private maxUserInvite = 5;
	private inviteTimeoutHours = 24; // hours

	private inviteLimitWarningMessage = 'We limit the number of invites sent from these recommendations. Please check back later.';


	constructor(
		private auth: AuthService,
		private nav: Router,
		private alertCtrl: AlertController
	) { }

	public connections(): Observable<any> {
		const firestore = getFirestore();
		const auth = getAuth();

		const userId = auth.currentUser?.uid;
		if (!userId) {
			return of([]);
		}

		const connectionsRef = collection(firestore, `${API.public_profiles}/${userId}/${API.connections}`);

		return new Observable<any>((observer) => {
			const unsubscribe = onSnapshot(connectionsRef, (snapshot) => {
				const connections = snapshot.docs.map((doc1) => {
					try {
						const data: DocumentData = doc1.data();
						const id = doc1.id;
						return { id, ...data };
					} catch (ex) {
						console.log('error getting connections: ' + ex);
						return {};
					}
				});

				observer.next(connections);
			});

			return () => unsubscribe();
		});
	}


	// GETS ANY PUBLIC PROFILE DATA
	public public_profile(targetUserId): Promise<WithId<CurbUser>> {
		const firestore = getFirestore();

		console.log('public_profile request: ' + targetUserId);
		return new Promise((resolve, reject) => {

			if (!targetUserId) {
				reject('EMPTY_USER_ID');
			}

			const targetUserRef = doc(firestore, `${API.public_profiles}`, targetUserId);
			getDoc(targetUserRef)
				.then((targetUser) => {
					if (targetUser.exists()) {
						const publicProfileData = targetUser.data();
						publicProfileData.id = targetUser.id;
						//		console.log('public profile doc is : ' + JSON.stringify(publicProfileData));
						resolve(publicProfileData as WithId<CurbUser>);
					} else {
						reject('No public profile document: ' + targetUserId);
					}
				})
				.catch((error) => reject(error));
		});
	}

	public async initSettings() {
		try {
			const limits = await this.getInviteLimits();

			if (limits && limits.maxUserInvite) {
				this.maxUserInvite = Number(limits.maxUserInvite);
				this.inviteTimeoutHours = Number(limits.inviteTimeoutHours);
				this.inviteLimitWarningMessage = String(limits.inviteLimitWarningMessage);
			}

			this.totalUserInvites = Number(localStorage.getItem('totalUserInvites')) || 0;
			this.lastUserInvite = new Date(localStorage.getItem('lastUserInvite')) || null;

			console.log('from storage: ' + this.lastUserInvite);
		}
		catch (err) {
			console.log('err in total invite task: ' + err);
			this.totalUserInvites = 0;
			this.lastUserInvite = null;
		}
	}



	async showLimitWarning() {
		const limitAlert = await this.alertCtrl.create({
			header: 'Recommendations Invite Limit',
			message: this.inviteLimitWarningMessage,
			buttons: [
				{
					text: 'OK',
					role: 'ok',
					handler: data => {

					}
				}
			]
		});
		await limitAlert.present();
	}


	public incrementSeeMoreCount() {
		this.seeMoreUsersClicked++;
		localStorage.setItem('seeMoreUsersClicked', String(this.seeMoreUsersClicked));
		console.log('incrementSeeMoreCount ' + this.seeMoreUsersClicked);
	}

	public incrementUserInviteCount() {
		if (this.totalUserInvites < this.maxUserInvite) {
			this.totalUserInvites++;
			localStorage.setItem('totalUserInvites', String(this.totalUserInvites));
			this.setLastUserInvite();
			console.log('totalUserInvites ' + this.totalUserInvites);
		}
	}

	public setLastUserInvite() {
		this.lastUserInvite = new Date();
		localStorage.setItem('lastUserInvite', String(this.lastUserInvite));

		console.log('lastUserInvite ' + this.lastUserInvite);
	}

	public canInviteUser(): boolean {
		if (!this.lastUserInvite) {
			return true;
		}

		const now = new Date();
		// Calculate the time difference in milliseconds
		const timeDifference = now.getTime() - this.lastUserInvite.getTime();
		// Convert the time difference to hours
		const hoursPassed = timeDifference / (1000 * 60 * 60); // 1000 milliseconds * 60 seconds * 60 minutes

		// reset total invites
		if (hoursPassed >= this.inviteTimeoutHours) {
			this.totalUserInvites = 0;
			this.seeMoreUsersClicked = 0;
			localStorage.setItem('totalUserInvites', String(this.totalUserInvites));
			localStorage.setItem('seeMoreUsersClicked', String(this.seeMoreUsersClicked));
			return true;
		}

		return (this.totalUserInvites < this.maxUserInvite);
	}

	public canSeeMore(): boolean {
		return (this.seeMoreUsersClicked < this.maxSeeMore);
	}



	getInviteLimits(): Promise<any> {
		const db = getFirestore();
		return new Promise((resolve, reject) => {

			if (this.inviteLimit) {
				resolve(this.inviteLimit);
			}

			const inviteLimitsRef = doc(db, API.backend_data, 'inviteLimits');
			getDoc(inviteLimitsRef)
				.then((inviteLimitsSnap) => {
					if (inviteLimitsSnap.exists()) {
						console.log('inviteLimit data is : ' + JSON.stringify(inviteLimitsSnap.data()));
						this.inviteLimit = inviteLimitsSnap.data();
						resolve(inviteLimitsSnap.data());
					} else {
						reject('No inviteLimit document!');
					}
				})
				.catch((error) => {
					reject(error);
				});
		});
	}

	// LOADS DETAILED CONNECTIONS PROFILES
	public loadConnections(populate = true) {
		const self = this;

		// load all connections
		if (self.myConnectionsSub) {
			return;
		}

		console.log('load connections with populate: ' + populate);

		self.myConnectionsSub = self.connections().subscribe((data) => {
			console.log('my connections data change: ' + JSON.stringify(data));
			self.myConnections = data;

			if (populate && !self.hasPopulatedConnections) { // Check if the loop has run
				self.hasPopulatedConnections = true; // Set the flag to true after first run

				for (let i = 0; i < data.length; i++) {
					//		console.log('connection iteration: ' + JSON.stringify(data));
					self.populateConnectionData(data[i].id);
				}
			}
		});
	}

	getConnectionDetails(item): Promise<any> {
		const self = this;

		return new Promise((resolve, reject) => {
			if (self.connectionsDetails[item]) {
				//			console.log('returning connection Details: ' + JSON.stringify(self.connectionsDetails[item]));
				resolve(self.connectionsDetails[item]);
			} else {
				// Return the promise from populateConnectionData
				this.populateConnectionData(item)
					.then((data) => resolve(data))
					.catch((error) => reject(error));
			}
		});
	}


	populateConnectionData(targetUser): Promise<any> {
		const self = this;

		return new Promise((resolve, reject) => {
			if (!targetUser) {
				reject('ERROR_NO_USER');
			}
			console.log('population connection data called ' + targetUser);
			this.public_profile(targetUser).then(res => {
				if (res) {
					//		console.log('adding profile to dictionary ' + JSON.stringify(res));

					if (!res.id) {
						res.id = targetUser;
					}

					self.connectionsDetails[res.id] = res;
					resolve(res);
				}
				else {
					reject('ERROR_NO_USER');
				}
			});
		});
	}
}
