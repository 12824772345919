import type { InstantSearchOptions, UiState, Hit } from 'instantsearch.js';

import { environment } from '../../environments/environment';
import { CurbUser } from '../app.models';
import { AppInjector } from '../app.module';
import { AuthService } from '../services/auth.service';

export type AlgoliaHit = Hit & CurbUser;

export type AlgoliaFilterHitsFn = (hitId: string) => boolean;

// Format is the following
// Page name +
// input (components with text input) OR automatic_search (components that automatically search without and input needed) +
// (optional) current user role +
// role we are searching for
export enum AlgoliaInstanceNames {
  my_profile_input_invite_admin = 'my_profile_input_invite_admin',
  my_paired_agents_input_admin_invite_agent = 'my_paired_agents_input_admin_invite_agent',
  my_paired_agents_input_lender_invite_agent = 'my_paired_agents_input_lender_invite_agent',
  my_paired_lenders_input_agent_invites_lender = 'my_paired_lenders_input_agent_invites_lender',
  enhanced_modal_input_agent_invites_admin = 'enhanced_modal_input_agent_invites_admin',
  enhanced_modal_input_agent_invites_lender = 'enhanced_modal_input_agent_invites_lender',
  my_paired_lenders_automatic_search_for_lenders = 'my_paired_lenders_automatic_search_for_lenders',
  my_paired_agents_automatic_search_for_agents = 'my_paired_agents_automatic_search_for_agents',
  listing_details_automatic_search_for_marketing_services = 'listing_details_automatic_search_for_marketing_services',
  new_listing_input_admin_search_for_agent = 'new_listing_input_admin_search_for_agent',
  my_paired_agents_input_search_for_agent = 'my_paired_agents_input_search_for_agent',
  my_paired_listings_input_admin_search_for_listings = 'my_paired_listings_input_admin_search_for_listings',
}

type AlgoliaConfig = InstantSearchOptions<UiState, UiState>;
interface AlgoliaInstanceConfig {
  refinements: string[];
  searchParameters?: Record<string, any>;
  instanceConfigs: Partial<AlgoliaConfig>;
  // Only available for the automatic search component
  searchOnLoad?: boolean;
}
export const ALGOLIA_CONFIGS: Record<
  AlgoliaInstanceNames,
  () => AlgoliaInstanceConfig | Promise<AlgoliaInstanceConfig>
> = {
  [AlgoliaInstanceNames.my_profile_input_invite_admin]: () => {
    const indexName = environment.production
      ? 'public_profiles_prod'
      : 'public_profiles_dev';

    const config = {
      indexName,
      initialUiState: {
        [indexName]: {
          refinementList: {
            role: ['agentAdmin'],
            enableLenderCommunication: ['true'],
          },
        },
      },
    };

    return {
      refinements: getRefinements(config, indexName),
      instanceConfigs: config,
    };
  },
  [AlgoliaInstanceNames.my_paired_agents_input_admin_invite_agent]: () => {
    const indexName = environment.production
      ? 'public_profiles_prod'
      : 'public_profiles_dev';
    const config = {
      indexName,
      initialUiState: {
        [indexName]: {
          refinementList: {
            role: ['agent'],
          },
        },
      },
    };

    return {
      refinements: getRefinements(config, indexName),
      instanceConfigs: config,
    };
  },
  [AlgoliaInstanceNames.my_paired_agents_input_lender_invite_agent]: () => {
    const indexName = environment.production
      ? 'public_profiles_prod'
      : 'public_profiles_dev';
    const config = {
      indexName,
      initialUiState: {
        [indexName]: {
          refinementList: {
            role: ['agent'],
            hasActivePreferredLender: ['false'],
            enableLenderCommunication: ['true'],
          },
        },
      },
    };

    return {
      refinements: getRefinements(config, indexName),
      instanceConfigs: config,
    };
  },
  [AlgoliaInstanceNames.my_paired_lenders_input_agent_invites_lender]: () => {
    const indexName = environment.production
      ? 'public_profiles_prod_lenders'
      : 'public_profiles_dev_lenders';
    const config = {
      indexName,
      initialUiState: {
        [indexName]: {
          refinementList: {
            role: ['lender'],
            enableLenderCommunication: ['true'],
          },
        },
      },
    };

    return {
      refinements: getRefinements(config, indexName),
      searchParameters: {
        filters: `(NOT regionalLender: 'true')`,
        hitsPerPage: 10,
      },
      instanceConfigs: config,
    };
  },
  [AlgoliaInstanceNames.enhanced_modal_input_agent_invites_admin]: () => {
    const indexName = environment.production
      ? 'public_profiles_prod'
      : 'public_profiles_dev';

    const config = {
      indexName,
      initialUiState: {
        [indexName]: {
          refinementList: {
            role: ['agentAdmin'],
            enableLenderCommunication: ['true'],
          },
        },
      },
    };

    return {
      refinements: getRefinements(config, indexName),
      searchParameters: {
        clickAnalytics: true,
        enablePersonalization: true,
      },
      instanceConfigs: config,
    };
  },
  [AlgoliaInstanceNames.enhanced_modal_input_agent_invites_lender]: () => {
    const indexName = environment.production
      ? 'public_profiles_prod_lenders'
      : 'public_profiles_dev_lenders';
    const config = {
      indexName,
      initialUiState: {
        [indexName]: {
          refinementList: {
            role: ['lender'],
            enableLenderCommunication: ['true'],
          },
        },
      },
    };

    return {
      refinements: getRefinements(config, indexName),
      searchParameters: {
        clickAnalytics: true,
        enablePersonalization: true,
      },
      instanceConfigs: config,
    };
  },
  [AlgoliaInstanceNames.my_paired_lenders_automatic_search_for_lenders]: () => {
    const indexName = environment.production
      ? 'public_profiles_prod_lenders'
      : 'public_profiles_dev_lenders';

    const instanceConfigs: Partial<AlgoliaConfig> = {
      indexName,
      initialUiState: {
        [indexName]: {
          refinementList: {
            role: ['lender'],
            enableLenderCommunication: ['true'],
            subscriptionStatus: ['active'],
          },
        },
      },
    };

    return {
      instanceConfigs,
      refinements: getRefinements(instanceConfigs, indexName),
      searchParameters: {
        clickAnalytics: true,
        enablePersonalization: true,
        hitsPerPage: 6,
      },
    };
  },
  [AlgoliaInstanceNames.my_paired_agents_automatic_search_for_agents]: async () => {
    const indexName = environment.production
      ? 'public_profiles_prod'
      : 'public_profiles_dev';

    const instanceConfigs: Partial<AlgoliaConfig> = {
      indexName,
      initialUiState: {
        [indexName]: {
          refinementList: {},
        },
      },
    };

    const user = await getCurrentUser();

    if (user.role === 'lender') {
      instanceConfigs.initialUiState[indexName].refinementList = {
        hasActivePreferredLender: ['false'],
        enableLenderCommunication: ['true'],
        role: ['agent'],
      };
    } else if (user.role === 'agentAdmin') {
      instanceConfigs.initialUiState[indexName].refinementList = {
        role: ['agent'],
      };
    } else {
      throw new Error('Invalid role');
    }

    return {
      instanceConfigs,
      refinements: getRefinements(instanceConfigs, indexName),
      searchParameters: {
        clickAnalytics: true,
        enablePersonalization: true,
        hitsPerPage: 6,
      },
    };
  },
  [AlgoliaInstanceNames.listing_details_automatic_search_for_marketing_services]:
    () => {
      const indexName = environment.production
        ? 'marketplace_prod'
        : 'marketplace_dev';

      const instanceConfigs: Partial<AlgoliaConfig> = {
        indexName,
        initialUiState: {
          [indexName]: {
            refinementList: {
              active: ['true'],
            },
          },
        },
      };

      return {
        instanceConfigs,
        refinements: getRefinements(instanceConfigs, indexName),
        searchParameters: {
          clickAnalytics: true,
        },
        searchOnLoad: true,
      };
    },
  [AlgoliaInstanceNames.new_listing_input_admin_search_for_agent]: async () => {
    const indexName = environment.production
      ? 'public_profiles_prod'
      : 'public_profiles_dev';

    const instanceConfigs: Partial<AlgoliaConfig> = {
      indexName,
      initialUiState: {
        [indexName]: {
          refinementList: {},
        },
      },
    };

    const user = await getCurrentUser();
    const uid = String(getUID());

    if (user.regionalLender === true) {
      instanceConfigs.initialUiState[indexName].refinementList = {
        pairedUserId: [uid],
        mostRecentListingEnhancedModeStatus: ['true'],
        enableLenderCommunication: ['true'],
        role: ['agent'],
      };
    } else if (user.role === 'lender') {
      instanceConfigs.initialUiState[indexName].refinementList = {
        pairedUserId: [uid],
        role: ['agent'],
      };
    } else if (user.role === 'agentAdmin') {
      instanceConfigs.initialUiState[indexName].refinementList = {
        pairedAdminId: [uid],
        role: ['agent'],
      };
    } else {
      throw new Error('Invalid role');
    }

    return {
      instanceConfigs,
      refinements: getRefinements(instanceConfigs, indexName),
    };
  },
  [AlgoliaInstanceNames.my_paired_agents_input_search_for_agent]: async () => {
    const indexName = environment.production
      ? 'public_profiles_prod'
      : 'public_profiles_dev';

    const instanceConfigs: Partial<AlgoliaConfig> = {
      indexName,
      initialUiState: {
        [indexName]: {
          refinementList: {},
        },
      },
    };

    const user = await getCurrentUser();
    const uid = String(getUID());

    if (user.regionalLender === true) {
      instanceConfigs.initialUiState[indexName].refinementList = {
        pairedUserId: [uid],
        mostRecentListingEnhancedModeStatus: ['true'],
        enableLenderCommunication: ['true'],
        role: ['agent'],
      };
    } else if (user.role === 'lender') {
      instanceConfigs.initialUiState[indexName].refinementList = {
        pairedUserId: [uid],
        role: ['agent'],
      };
    } else if (user.role === 'agentAdmin') {
      instanceConfigs.initialUiState[indexName].refinementList = {
        pairedAdminId: [uid],
        role: ['agent'],
      };
    } else {
      throw new Error('Invalid role');
    }

    return {
      instanceConfigs,
      refinements: getRefinements(instanceConfigs, indexName),
    };
  },
  [AlgoliaInstanceNames.my_paired_listings_input_admin_search_for_listings]:
    async () => {
      const indexName = environment.production
        ? 'listings_prod'
        : 'listings_dev';

      const instanceConfigs: Partial<AlgoliaConfig> = {
        indexName,
        initialUiState: {
          [indexName]: {
            refinementList: {},
          },
        },
      };

      const user = await getCurrentUser();
      const uid = String(getUID());

      if (user.role === 'lender') {
        instanceConfigs.initialUiState[indexName].refinementList = {
          'listingSnapshot.loanOfficer': [uid],
          'listingSnapshot.enhancedMode': ['true'],
        };
      } else if (user.role === 'agentAdmin') {
        instanceConfigs.initialUiState[indexName].refinementList = {
          'listingSnapshot.agentAdmin': [uid],
        };
      } else {
        throw new Error('Invalid role');
      }

      return {
        instanceConfigs,
        searchParameters: { filters: `(NOT listingSnapshot.archived: true)` },
        refinements: getRefinements(instanceConfigs, indexName),
      };
    },
} as const;

function getRefinements(config: Partial<AlgoliaConfig>, indexName: string) {
  return Object.keys(config.initialUiState[indexName].refinementList);
}

function getCurrentUser() {
  return AppInjector.get(AuthService).getFullUser();
}

function getUID() {
  return AppInjector.get(AuthService).getUID();
}
