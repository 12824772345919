/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/naming-convention */

import { Injectable, SecurityContext } from '@angular/core';
import { UserColors, PropertyLayout } from '../constants';
import { Platform } from '@ionic/angular';
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';

import { Observable, merge, fromEventPattern } from 'rxjs';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { ImagesManagementService } from './images-management.service';
import { AuthService } from './auth.service';
import { DomSanitizer } from '@angular/platform-browser';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';

export enum KeyboardAction {
  SHOW,
  HIDE
}

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  lastWidth = 0;
  lastHeight = 0;
  public keyboardHeight = 0;

  constructor(
    private plt: Platform,
    private keyboard: Keyboard,
    private screenOrientation: ScreenOrientation,
    private images: ImagesManagementService,
    private auth: AuthService,
    private sanitizer: DomSanitizer,
    private socialSharing: SocialSharing,
    private file: File
  ) {

  }

  deviceAspectRatio() {
    if (this.isMobilePhone()) {
      return this.plt.width() / this.plt.height();
    }
    return this.plt.height() / this.plt.width();
  }
  innerCalcHeight() {
    const vh = this.plt.height();
    const vw = this.plt.width();
    if (vw !== this.lastWidth && vh !== this.lastHeight) {
      this.lastWidth = vw;
      this.lastHeight = vh;
      document.documentElement.style.setProperty('--vh', `${vh / 100}px`);
    }
  }

  calcVh() {
    this.plt.resize.subscribe(() => {
      this.innerCalcHeight();
    });
    this.innerCalcHeight();
  }
  avatarColor(name: string) {
    let charSum = 0;
    for (let x = 0; x < name.length; x++) {
      const c = name.charCodeAt(x);
      charSum += c;
    }
    return '#' + UserColors[charSum % UserColors.length];
  }

  convertColor(color) {
    if (color.substring(0, 1) === '#') {
      color = color.substring(1);
    }
    return {
      rChannel: parseInt(color.substring(0, 2), 16),
      gChannel: parseInt(color.substring(2, 4), 16),
      bChannel: parseInt(color.substring(4), 16),
      aChannel: 1
    };
  }

  propertyLayoutOrientation(layout: number) {
    let orientation = this.screenOrientation.ORIENTATIONS.ANY;
    switch (layout) {
      case PropertyLayout.LANDSCAPE_1: {
        orientation = this.screenOrientation.ORIENTATIONS.LANDSCAPE;
        break;
      }
      case PropertyLayout.LANDSCAPE_2: {
        orientation = this.screenOrientation.ORIENTATIONS.LANDSCAPE;
        break;
      }
      case PropertyLayout.PORTRAIT_1: {
        orientation = this.screenOrientation.ORIENTATIONS.PORTRAIT;
        break;
      }
      case PropertyLayout.PORTRAIT_2: {
        orientation = this.screenOrientation.ORIENTATIONS.PORTRAIT;
        break;
      }
    }
    return orientation;
  }

  calcPropertyLayout(layout: number) {
    let width = this.plt.width();
    let height = this.plt.height();
    switch (layout) {
      case PropertyLayout.LANDSCAPE_1: {
        width = Math.max(this.plt.width(), this.plt.height());
        height = Math.min(this.plt.width(), this.plt.height());
        break;
      }
      case PropertyLayout.LANDSCAPE_2: {
        width = Math.max(this.plt.width(), this.plt.height());
        height = Math.min(this.plt.width(), this.plt.height());
        break;
      }
      case PropertyLayout.PORTRAIT_1: {
        width = Math.min(this.plt.width(), this.plt.height());
        height = Math.max(this.plt.width(), this.plt.height());
        break;
      }
      case PropertyLayout.PORTRAIT_2: {
        width = Math.min(this.plt.width(), this.plt.height());
        height = Math.max(this.plt.width(), this.plt.height());
        break;
      }
    }
    return { width: width, height: height };
  }

  isMobilePhone() {

    // CATCH ALL GOOGLE PIXEL PHONES
    if (navigator.userAgent.indexOf('Pixel 8') > -1 ||
      navigator.userAgent.indexOf('Pixel 7') > -1 ||
      navigator.userAgent.indexOf('Pixel 6') > -1 ||
      navigator.userAgent.indexOf('Pixel 5') > -1 ||
      navigator.userAgent.indexOf('Pixel 4') > -1 ||
      navigator.userAgent.indexOf('Pixel 3') > -1) {
      return true;
    }
    else if (this.plt.is('android')) {
      //  return this.plt.is('mobile') && !this.isTabletDevice();
      return (!this.plt.is('tablet') && !this.isTabletDevice());
    }
    else if (this.plt.is('ios')) {
      return this.plt.is('mobile') && !this.plt.is('ipad');
    }
    else {
      return false;
    }
  }


  // some android devices report wrong device type
  isTabletDevice() {
    // const smallest = Math.min(this.plt.width(), this.plt.height());
    // const largest = Math.max(this.plt.width(), this.plt.height());
    const smallest = Math.min(window.innerWidth, window.innerHeight);
    const largest = Math.max(window.innerWidth, window.innerHeight);

    console.log('smallest: ' + smallest + ' largest: ' + largest);
    console.log('is tablet: ' + (smallest > 460 && smallest <= 900) && (largest > 780 && largest < 1400));
    console.log(smallest + ' ' + largest);
    return (smallest > 900) &&
      (largest > 1200);
  }

  // min kiosk Width requirement
  isDeviceWideEnough() {
    return (this.plt.width() > 650);
  }

  mapPropertyLayout(layout) {
    if (this.isMobilePhone()) {
      switch (layout) {
        case PropertyLayout.LANDSCAPE_1: {
          return PropertyLayout.PORTRAIT_1;
        }
        case PropertyLayout.LANDSCAPE_2: {
          return PropertyLayout.PORTRAIT_2;
        }
      }
    }

    return layout;
  }
  unlockOrientation() {
    if (this.plt.is('cordova')) {
      if (!this.isMobilePhone()) {

        console.log(`utils: unlock`);
        this.screenOrientation.unlock();
      } else {
        console.log(`utils: lock portrait`);
        this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);

      }
    }
  }

  lockOrientation(orientation) {
    if (this.plt.is('cordova')) {
      if (this.isMobilePhone()) {
        this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
      } else {
        this.screenOrientation.lock(orientation);
      }
    }
  }

  keyboardEvents(): Observable<any> {
    return fromEventPattern(
      (handler) => {
        const showSub = this.keyboard.onKeyboardShow().subscribe((event) =>
          handler({ action: KeyboardAction.SHOW, height: event.keyboardHeight })
        );
        const hideSub = this.keyboard.onKeyboardHide().subscribe(() =>
          handler({ action: KeyboardAction.HIDE, height: 0 })
        );
        return {
          unsubscribe: () => {
            showSub.unsubscribe();
            hideSub.unsubscribe();
          },
        };
      }
    );
  }

  logoPath(url) {
    console.log('logoPath create with url ' + url);
    //  let ref = url.split('logo/')[1];
    //  let fileId = ref.split('_logo')[0];

    return `${this.auth.getCurrentUser().uid}_logo`;
  }

  hashCode(str) {
    let hash = 0; let i = 0; const len = str.length;
    while (i < len) {
      // eslint-disable-next-line no-bitwise
      hash = ((hash << 5) - hash + str.charCodeAt(i++)) << 0;
    }
    return hash;
  }

  updateLogoFile(url, override = true) {
    return new Promise((resolve) => {
      //   this.images.realImageData(url).then((data) => {
      // const path = this.fileService.dataDirectory;
      console.log('updateLogoFile called ' + url);
      this.images.cacheImage(url, this.logoPath(url), override).then(() => {
        resolve(true);
      }).catch((error) => {
        console.log('error updating logo file ' + error);
        resolve(false);
      });
      //   });
    });
  }

  isInAppBrowser(): boolean {

    const userAgent = JSON.stringify(navigator.userAgent);

    return (userAgent.indexOf('FBIOS') > -1)
      || (userAgent.indexOf('Instagram') > -1)
      || (userAgent.indexOf('FBAN') > -1)
      || (userAgent.indexOf('FBAV') > -1)
      || (userAgent.indexOf('MessengerLiteForiOS') > -1);

  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  blobToDataURL(blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  async downloadFile(content: string | ArrayBuffer, contentType: string, fileName: string) {
    const blob = new Blob([content], { type: contentType });

    if (this.plt.is('cordova')) {
      const directory = this.file.dataDirectory + `/pdfs`;

      // At least on iOS, the only way to name the file you are sharing is first to store it somewhere and then share it
      const fileEntry = await this.file.writeFile(directory, fileName, blob, { replace: true });
      const filePath = fileEntry.nativeURL;

      await this.socialSharing.share(null, null, filePath);

      // Remove the temporary file
      await this.file.removeFile(directory, fileName);
      return;
    }

    console.log('Downloading file for the web');

    // Setup
    const urlBuilder = window.URL || window.webkitURL;
    const url = urlBuilder.createObjectURL(blob);
    const sanitizedUrl = this.sanitizer.sanitize(SecurityContext.URL, this.sanitizer.bypassSecurityTrustResourceUrl(url));

    const link = document.createElement('a');
    link.href = sanitizedUrl;
    link.download = fileName;
    document.body.appendChild(link);

    // Trigger download
    link.click();

    // Cleanup
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }

  ensureTextEndsWith(text: string, suffix: string): string {
    return text.endsWith(suffix) ? text : text + suffix;
  }
}
