import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AlertController, AlertOptions, ModalController } from '@ionic/angular';
import { CommonProvider } from '../../services/common';
import { NetworkProvider } from '../../services/network';
import { EditInputPage } from '../edit-input/edit-input';

@Component({
  selector: 'change-app-setting',
  templateUrl: 'change-app-setting.html',
  styleUrls: ['change-app-setting.scss'],
})
export class ChangeAppSetting {
  @Input() settingName: string;
  @Input() settingCurrentValue: string;
  @Input() settingPlaceholder: string;
  // @TODO(Elian): Implement a `toggle` mode to migrate the other configs in the `my-profile` page
  @Input() mode = 'open-modal';

  // `open-modal` mode fields
  @Input() moreInfoModalProps:
    | {
        header: string;
        message: string;
        moreInfoExternalLink?: string;
      }
    | undefined;
  @Input() modalComponentToOpen = EditInputPage;
  @Input() modalComponentProps: Record<string, any> = {};
  @Output() onModalClose = new EventEmitter();

  // Testing related
  @Input() nameFieldTestId = '';
  @Input() iconTestId = '';

  initialValue: string;

  constructor(
    private common: CommonProvider,
    private network: NetworkProvider,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController
  ) {
    this.initialValue = this.settingCurrentValue;
  }

  async openModal() {
    const modal = await this.modalCtrl.create({
      component: this.modalComponentToOpen,
      componentProps: this.modalComponentProps,
    });

    await modal.present();

    const { data } = await modal.onDidDismiss();

    if (this.initialValue !== data) {
      this.onModalClose.emit(data || '');
    }
  }

  async displayMoreInfoModal(event: Event) {
    event.stopPropagation();
    if (!this.moreInfoModalProps) {
      return;
    }

    const { header, message, moreInfoExternalLink } = this.moreInfoModalProps;

    const buttons: AlertOptions['buttons'] = [
      {
        text: 'OK',
        role: 'cancel',
      },
    ];

    if (moreInfoExternalLink) {
      buttons.push({
        text: 'Help Section',
        cssClass: 'no-transform',
        handler: () => {
          if (!this.network.checkInternet()) {
            this.network.noNetworkModal();
            return;
          }
          this.common.openLink(moreInfoExternalLink);
        },
      });
    }

    const alert = await this.alertCtrl.create({
      header,
      message,
      buttons,
    });

    await alert.present();
  }
}
