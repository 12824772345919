/* eslint-disable @typescript-eslint/naming-convention */

const SERVER_URL = "https://us-central1-block-party-dev.cloudfunctions.net";

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyDdQ1b57p_EfAnt-AA5B0xpJ7Z13x5T6wc',
    authDomain: 'block-party-dev.firebaseapp.com',
    databaseURL: 'https://block-party-dev.firebaseio.com',
    projectId: 'block-party-dev',
    storageBucket: 'block-party-dev.appspot.com',
    messagingSenderId: '711860746340',
    appId: '1:711860746340:web:85e11307d26e965632c771',
    measurementId: 'G-XLRMGBMZWW'
  },
  twilio: {
    twilioAccountSId: '',
    twilioAuthToken: '',
    twilioApiUrl: 'https://api.twilio.com/2010-04-01/Accounts/',
    twilioFromNumber: '+18558272789'
  },
  intercom: {
    app_id: 'w3p9bddr',
    tag_url: 'https://block-party-dev.web.app/tag-intercom',
  },
  zapier: {
    lenderEmailWebhook: 'https://block-party-dev.web.app/lenderEmailWebhook'
  },

  usernameCheckRequest: 'https://block-party-dev.web.app/checkUsername',
  log_errors: 'https://block-party-dev.web.app/log-errors',
  imagesPath: '/block-party-app/assets/kiosk-screens/',
  onlylink: 'https://staging.block-party.app/',
  blockparty: 'https://staging.block-party.app/',
  webapp: 'https://webapp-block-party-dev.web.app',
  storageLogos: 'https://firebasestorage.googleapis.com/v0/b/block-party-dev.appspot.com/o/logos%2F',

  useranalytics: {
    url: 'https://block-party-dev.web.app/user-analytics',
    user: 'g6fQqDdw',
    salt: 'c71dba077194a338b92dbb0ffc7ef27bea0a514c61461da7db7c8de3a49d25af9f8038d460b8117ccc472794c460b55e913efc5a27671c87beec612b420ce63e'
  },

  rollout_token_url: 'https://block-party-dev.web.app/oauth/rollout_token',
  rollout_app: 'curb-hero',
  rollout_base_url: 'https://curb-hero-sandbox.rolloutapp.com/api',
  // rollout_base_url: 'https://curb-hero.rolloutapp.com/api',

  referralURL: ' https://pair.contact/',

  // AGENT CONNECTION LINKS
  connectionInvite: 'https://block-party-dev.web.app/invite-agent',
  connectionAccept: 'https://block-party-dev.web.app/pair-agent',
  connectionUnpair: 'https://block-party-dev.web.app/unpair-agent',
  inviteAgentEmail: 'https://block-party-dev.web.app/invite-agent-email',

  // LENDER CONNECTION LINKS
  connectionInviteLender: 'https://block-party-dev.web.app/invite-lender',
  connectionAcceptLender: 'https://block-party-dev.web.app/pair-lender',
  connectionUnpairLender: 'https://block-party-dev.web.app/unpair-lender',
  inviteLender: 'https://block-party-dev.web.app/invite-lender-id',

  // AGENT ADMIN CONNECTION LINKS
  inviteAgentAdmin: 'https://block-party-dev.web.app/invite-agentadmin',
  connectionAcceptAgentAdmin: 'https://block-party-dev.web.app/pair-agent-agentadmin',
  connectionUnpairAgentAdmin: 'https://block-party-dev.web.app/unpair-agent-agentadmin',
  connectionAcceptAgentAdmin_Agent: 'https://block-party-dev.web.app/pair-agentadmin-agent',

  getTokenRequest: 'https://block-party-dev.web.app/user-auth-token',
  stripeSubscription: 'https://block-party-dev.web.app/subscription?token=',
  createUserDBWeb: 'https://block-party-dev.web.app/create-user',
  leadLookup: 'https://block-party-dev.web.app/lead-lookup',
  contactLookup: 'https://block-party-dev.web.app/contact-lookup',

  downloadDisclosure: 'https://webapp-block-party-dev.web.app/download-disclosure/',

  lenderReviewImages: 'assets/imgs/lender_reviews/',

  lender_julie_old: 'zoApHOq4OtEWFvZZcULQ',
  lender_julie_new: 'KuxcFjhsh0PhpSCsI0oTjcZpTpW2',
  lender_wendy_old: 'NgXTBXrjIKekm5pJv995',
  lender_wendy_new: 'WilYeWu459MKlSOsY8HFhNxpBAy2',

  maps_key: 'AIzaSyCCpIMARUsGvOqfP6WfcLAF35WedSlLrb0',

  captcha_site_key: '6Lfft7spAAAAAF4GIho5-9ZdgHcE-dj8_dQebZqV',
  appCheckDevLocal: false,
  appCheckEnabled: true,

  algolia: {
    appId: "N26A8PC608",
    apiKey: "068c90fcb4bdad125a840343a2725949",
    listings: 'listings_dev',
    public_profiles: 'public_profiles_dev'
  },

  enablePersistence: false,
  serviceWorkerEnabled: true,
  appversion: ' Version 4.2.26 Dev',

  // HTTP Endpoints
  saveLead: `${SERVER_URL}/saveLead`,
  whoAmI: `${SERVER_URL}/whoAmI`,
  impersonateUser: `${SERVER_URL}/impersonateUser`,
  removeUserImpersonation: `${SERVER_URL}/removeImpersonation`,
  getDisclosureDownloadData: `${SERVER_URL}/getDisclosureDownloadData`,
};
