import { Component, Input, OnInit, TemplateRef } from '@angular/core';

import connectInfiniteHits from 'instantsearch.js/es/connectors/infinite-hits/connectInfiniteHits';
import connectSearchBox from 'instantsearch.js/es/connectors/search-box/connectSearchBox';
import connectRefinementList from 'instantsearch.js/es/connectors/refinement-list/connectRefinementList';
import connectConfigure from 'instantsearch.js/es/connectors/configure/connectConfigure';
import connectHierarchicalMenu, {
  HierarchicalMenuItem,
} from 'instantsearch.js/es/connectors/hierarchical-menu/connectHierarchicalMenu';
import InstantSearch from 'instantsearch.js/es/lib/InstantSearch';

import {
  AlgoliaHit,
  AlgoliaInstanceNames,
  ALGOLIA_CONFIGS,
} from '../../constants/algolia';
import { noop } from '../../../utils/utils';
import { BaseAlgoliaComponent } from '../../../utils/algolia';
import { User_global } from '../../app.models';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'algolia-search-with-filters',
  templateUrl: './algolia-search-with-filters.html',
  styleUrls: ['algolia-search-with-filters.scss'],
})
export class AlgoliaSearchWithFilters
  extends BaseAlgoliaComponent
  implements OnInit
{
  // Name of the algolia instance to use, that holds information like the index and default refinements
  @Input() instanceName:
    | AlgoliaInstanceNames.my_paired_agents_input_search_for_agent
    | AlgoliaInstanceNames.my_paired_listings_input_admin_search_for_listings;
  // Message to display when no results are found
  @Input() noResultsMessage = '';

  @Input() childComponent!: TemplateRef<any>;

  // Hierarchical menu related fields

  @Input() restrictStates: boolean;
  @Input() restrictToStates: any = [];
  @Input() locationData: any = [];

  selectedLvl0: string;
  selectedLvl1: string;

  disableCitiesSelect = true;
  eligibleStates = [];
  refineHierachicalMenu: (value: string) => void;
  // This is an array that, at the top level contains one entry per state (lvl0), (optionally) one per city (lvl1) and finally
  // (optionally) one per address (lvl2). The entries in the array are the actual values of the records in the index.
  items: HierarchicalMenuItem[] = [];

  // Algolia related fields
  instance: InstantSearch;
  refine: (query: string) => void;
  query: string;
  hits: AlgoliaHit[] = [];

  searchText: string;
  isInputDirty = false;

  queryNextPage: () => void;
  isLastPage: boolean;

  recentlyActiveOption = 'all';
  defaultSearchParameters = {};
  elegibleStatesInitialized = false;

  constructor(private alertCtrl: AlertController) {
    super();
  }

  async ngOnInit() {
    if (
      this.instanceName !== AlgoliaInstanceNames.my_paired_agents_input_search_for_agent &&
      this.instanceName !== AlgoliaInstanceNames.my_paired_listings_input_admin_search_for_listings
    ) {
      throw new Error('Invalid instance name');
    }
    
    const { instance, configs } = await this.createInstance(this.instanceName);

    this.instance = instance;
    const { refinements = [], searchParameters = {} } = configs;

    this.defaultSearchParameters = searchParameters;

    this.instance.addWidgets([
      connectConfigure(noop)({
        searchParameters,
      }),
      connectSearchBox((state) => {
        this.refine = state.refine;
        this.query = state.query;
      })({}),
      connectInfiniteHits(({ hits, showMore, isLastPage }) => {
        this.hits = hits as any;
        this.queryNextPage = showMore;
        this.isLastPage = isLastPage;
      })({}),
      connectHierarchicalMenu(({ refine, items }) => {
        this.refineHierachicalMenu = refine;
        this.items = items;

        // We initialied the state once and only we results are first loaded in
        if (!this.elegibleStatesInitialized && this.items.length) {
          this.setElegibleStates();
          this.elegibleStatesInitialized = true;
        }
      })({
        attributes: this.locationData,
        separator: ' > ',
        showParentLevel: true,
        limit: 1000,
        showMore: false,
        showMoreLimit: 1000,
      }),
      ...refinements.map((refinement) => {
        return connectRefinementList(noop)({
          attribute: refinement,
          operator: 'and',
          limit: 10000,
        });
      }),
    ]);

    this.search();
  }

  onInputChange(text: string) {
    this.isInputDirty = true;
    this.searchText = text;
    this.refine(this.searchText);
  }

  clearSearch() {
    this.searchText = '';
    this.hits = [];
    this.refine('');
  }

  displayNoResultsLabel() {
    return (
      this.instance?.status === 'idle' && this.isLastPage && !this.hits.length
    );
  }

  displaySeeMoreButton() {
    return (
      this.hits.length > 0 &&
      !this.isLastPage &&
      this.instance?.status === 'idle'
    );
  }

  recentlyActiveSelectionChange() {
    if (
      this.instanceName ===
      AlgoliaInstanceNames.my_paired_agents_input_search_for_agent
    ) {
      if (this.recentlyActiveOption === 'all') {
        this.instance.helper.setQueryParameter('filters', '');
      } else {
        this.instance.helper.setQueryParameter(
          'filters',
          `mostRecentLeadCreatedAt > ${this._calculateDate()}`
        );
      }
    } else {
      if (this.recentlyActiveOption === 'all') {
        this.instance.helper.setQueryParameter(
          'filters',
          '(NOT listingSnapshot.archived: true)'
        );
      } else if (this.recentlyActiveOption) {
        const filter =
          User_global.regionalLender === true
            ? 'createdAt'
            : 'mostRecentLeadCreatedAt';
        this.instance.helper.setQueryParameter(
          'filters',
          `listingSnapshot.${filter} > ${this._calculateDate()}`
        );
      }
    }

    this.refine(this.query);
  }

  _calculateDate() {
    const d = new Date();
    d.setDate(d.getDate() - Number(this.recentlyActiveOption));
    return d.getTime();
  }

  private setElegibleStates() {
    // If there is a restriction in place, we only include those states
    if (this.restrictStates && this.restrictToStates.length) {
      this.eligibleStates = this.items.filter((el) =>
        this.restrictToStates.includes(el.value)
      );
    } else {
      this.eligibleStates = this.items;
    }
  }

  updateLevel0(value) {
    this.refineHierachicalMenu(value);
    this.selectedLvl0 = value;
    this.disableCitiesSelect = false;
  }

  updateLevel1(value) {
    this.refineHierachicalMenu(value);
    this.selectedLvl1 = value;
  }

  getLevel1() {
    if (!this.selectedLvl0 || !this.items.length) return;

    return this.items.find((el) => el.value === this.selectedLvl0);
  }

  reset() {
    this.refineHierachicalMenu('');
    this.clearSearch();
    this.recentlyActiveOption = 'all';
    // We need to reset the filters, since we set the option to `all` calling this method will set the right value
    this.recentlyActiveSelectionChange();

    this.selectedLvl0 = '';
    this.selectedLvl1 = '';
    this.disableCitiesSelect = true;
  }

  getRecentActivityFilterLabel() {
    if (
      this.instanceName ===
        AlgoliaInstanceNames.my_paired_listings_input_admin_search_for_listings &&
      User_global.regionalLender
    ) {
      return 'By Date Created';
    } else {
      return 'By Recent Activity';
    }
  }

  async checkForInvalidCity() {
    if (this.disableCitiesSelect) {
      const myAlert = await this.alertCtrl.create({
        header: 'State Required',
        message: 'Before filtering by a city, a state needs to be selected',
        buttons: [
          {
            text: 'OK',
            role: 'cancel',
          },
        ],
      });

      await myAlert.present();
    }
  }
}
