import algoliasearch from 'algoliasearch/lite';
import InstantSearch from 'instantsearch.js/es/lib/InstantSearch';
import aa from 'search-insights';
import {
  AlgoliaInstanceNames,
  ALGOLIA_CONFIGS,
} from '../app/constants/algolia';
import { environment } from '../environments/environment';

const searchClient = algoliasearch(
  environment.algolia.appId,
  environment.algolia.apiKey
);

type ConfigsAllowedToChange = Parameters<
  InstantSearch['helper']['setQueryParameter']
>[0];

export class BaseAlgoliaComponent {
  instance: InstantSearch;

  async createInstance(instanceName: AlgoliaInstanceNames) {
    if (!ALGOLIA_CONFIGS[instanceName]) {
      throw new Error(`No configuration found for instance ${instanceName}`);
    }

    const configs = await ALGOLIA_CONFIGS[instanceName]();
    const { instanceConfigs } = configs;

    // TODO initialize once only
    aa('init', {
      appId: environment.algolia.appId,
      apiKey: environment.algolia.apiKey,
    });

    const instance = new InstantSearch({
      searchClient,
      future: { preserveSharedStateOnUnmount: true },
      // @TODO: This option is deprecated, use `onStateChange` instead
      searchFunction: (helper) => {
        // We always ignore the search if the value us `undefined`. This is the value set on the automatic query that happens as soon as Algolia loads
        // This is the desired behaviour in componets with inputs, where we want to trigger the search only when the user types something
        // But, in the `automatic` search components we do want to trigger the search automatically, there, we set the query to an empty string
        if (helper.state.query === undefined) {
          return;
        }

        helper.search();
      },
      insights: {
        insightsClient: (window as any).aa,
      },
      ...instanceConfigs,
    });
    instance.start();

    return {
      instance,
      configs
    };
  }

  updateConfigs(newConfigs: ConfigsAllowedToChange) {
    for (const [key, value] of Object.entries(newConfigs)) {
      this.instance.helper.setQueryParameter(
        key as ConfigsAllowedToChange,
        value
      );
    }

    this.search();
  }

  search() {
    // Just calling `search` won't work because the default query value is `undefined`, which we are set to ignore.
    // So, we need to set the query to an empty string to trigger the search
    this.instance.helper.setQuery('').search();
  }
}
