/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable eqeqeq */
/* eslint-disable max-len */
import { Component, ViewChild, SecurityContext, AfterViewInit } from '@angular/core';
import { ModalController, NavParams, LoadingController, Platform, AlertController, MenuController, InfiniteScrollCustomEvent } from '@ionic/angular';
import { GuestService } from '../services/guest.service';
import { QuestionsAnswers, CurbUser, User_global } from '../app.models';
import { Subscription } from 'rxjs';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { CommonProvider } from '../services/common';
import { NetworkProvider } from '../services/network';
import { ListingDataProviderService } from '../services/listing-data-provider.service';
import { DomSanitizer } from '@angular/platform-browser';

import { ConnectionsService } from '../services/connections.service';
import { MyLeadsDetailsComponent } from '../components/my-leads-detail/my-leads-details';

import { AuthService } from '../services/auth.service';
import { UtilsService } from '../services/utils.service';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { I8nService } from '../services/i8nService';
import { FilterLeadsPopupComponent } from '../components/filter-leads-popup/filter-leads-popup.component';
import { combineLatest, of, BehaviorSubject, Observable, EMPTY, asyncScheduler, asapScheduler } from 'rxjs';
import { map } from 'rxjs/operators';
import { SqlService } from '../services/sql.service';

@Component({
  selector: 'app-my-leads',
  templateUrl: 'my-leads.html',
  styleUrls: ['./my-leads.scss']
})
export class MyLeadsPage {
  @ViewChild('bodyHolder') bodyHolder: any;
  @ViewChild('headerHolder') headerHolder: any;
  @ViewChild('filterSelect') filterSelect: any;

  public listing: any;
  public finalRes: any;
  public finalResPaginated = [];
  public arr: any;
  public searchRes: any;
  public selectedListing = '0';
  public currentSortDir = 'asc';
  public currentSortColumn = 'date';
  public showSkeleton = true;
  public heading: string;
  public description: string;
  public buttonText: string;
  public numberOfGuest: number;
  filterSelectOptions: any;
  leadsTitle: string;
  public leadsSubs: Subscription;
  showRefresh: boolean;

  userData: CurbUser;
  env: any = {};
  loading = true;
  loaded = false;

  maxLookups = 5;
  currentLookupTries: number;
  userSubs: any;
  lookupFormData = '';
  subscriptionStatus = 'active';
  lookupDisabled = false;
  leadLookupInfoText = 'You need to pair agents to get new leads, but you can try our lead lookup and verification for free';
  loadInterval: any;
  userRole: string;
  showPartialLeads = true;
  showMortgageLeads = true;
  offlineOrStaleData = false;
  totalGuestList = [];
  cordova = false;
  offlineSyncRunning = false;
  listingServiceData;
  paginationSize = 50;

  constructor(public navCtrl: Router,
    private modalCtrl: ModalController,
    private guestservice: GuestService,
    public loadingCtrl: LoadingController,
    public file: File,
    public plt: Platform,
    public navParams: NavParams,
    private socialSharing: SocialSharing,
    public common: CommonProvider,
    public network: NetworkProvider,
    private listings: ListingDataProviderService,
    private sanitizer: DomSanitizer,
    private connectionsService: ConnectionsService,
    private auth: AuthService,
    private utils: UtilsService,
    public alertCtrl: AlertController,
    private menu: MenuController,
    public i8nService: I8nService,
    private sql: SqlService
  ) {

    if (this.navCtrl.getCurrentNavigation() && this.navCtrl.getCurrentNavigation().extras.queryParams) {
      this.listing = this.navCtrl.getCurrentNavigation().extras.queryParams.listingId;
    }
  }

  ionViewDidEnter() {
    console.log('my leads did enter');
    this.offlineSyncRunning = false;
    if (this.auth.userInitialized) {
      this.initPage();
    }
    else {
      this.loadInterval = setInterval(() => {
        if (this.auth.userInitialized) {
          clearInterval(this.loadInterval);
          this.initPage();
        }
      }, 2000);
    }
  }

  initHorizontalScroll() {

    setTimeout(() => {
      try {
        this.bodyHolder.nativeElement.onscroll = () => {
          const content = this.bodyHolder.nativeElement;
          const header = this.headerHolder.nativeElement;
          header.scrollLeft = content.scrollLeft;
        };
      }
      catch (err) {
        console.log('error linking scrolling');
      }
    }, 2000);

  }

  initPage() {

    if (this.navCtrl.getCurrentNavigation() && this.navCtrl.getCurrentNavigation().extras.queryParams) {
      this.listing = this.navCtrl.getCurrentNavigation().extras.queryParams.listingId;
    }

    if (this.plt.is('cordova')) {
      this.cordova = true;

      this.plt.resume.subscribe((e) => {
        this.loaded = false;
        console.log('app RESUME event code!!!');
      });

      this.plt.pause.subscribe((e) => {
        this.loaded = false;
        console.log('app PAUSE event code!!!');
      });

    }

    this.menu.enable(true);
    this.menu.swipeGesture(true, 'left');

    const self = this;

    this.arr = [];

    this.heading = 'No Leads Found';
    this.description = 'This is where your Open House Leads appear after they sign in';
    this.buttonText = 'Refresh';
    this.filterSelectOptions = {
      header: 'Listing Address'
    };

    this.network.checkFirestoreDataConnection();
    this.loadLeads();

    this.userRole = User_global.role;

    if (this.userRole === 'lender') {
      this.userSubs = combineLatest([this.auth.userDataObserver()]).pipe
        (map(([user]) => ({ user }), asapScheduler)).subscribe((data) => {

          if (data && data.user) {
            console.log('user data subscribe: ' + JSON.stringify(data));
            self.userData = data.user;
            //   self.currentLookupTries = self.userData.totalLeadsLookups || 0;
            self.subscriptionStatus = self.userData.subscriptionStatus || '';
            //   self.lookupDisabled = self.userData.subscriptionStatus !== 'active' && self.currentLookupTries >= self.maxLookups;

            if (self.subscriptionStatus === 'active') {
              this.leadLookupInfoText = 'You need to pair agents to get new leads, but you can try our lead lookup and verification for free';
            }
          }
        });
    }

    else if (this.userRole === 'agent') {
      //agent
    }
    else if (this.userRole === 'agentAdmin') {
      //agentAdmin
      this.userSubs = combineLatest([this.auth.userDataObserver()]).pipe
        (map(([user]) => ({ user }), asapScheduler)).subscribe((data) => {

          if (data && data.user) {
            console.log('user data subscribe: ' + JSON.stringify(data));
            self.userData = data.user;
            //   self.currentLookupTries = self.userData.totalLeadsLookups || 0;
            self.subscriptionStatus = self.userData.subscriptionStatus || '';
            //   self.lookupDisabled = self.userData.subscriptionStatus !== 'active' && self.currentLookupTries >= self.maxLookups;

            if (self.subscriptionStatus === 'active') {
              this.leadLookupInfoText = 'You need to pair agents to get new leads, but you can try our lead lookup and verification for free';
            }
          }
        });
    }

    console.log('init leads page');
  }

  loadLeads() {
    console.log('getting guests for ' + this.listing);
    if (this.listing !== undefined && this.listing !== null) {
      this.selectedListing = this.listing;
      this.getMyleads(this.listing);
    }
    else {
      this.getMyleads(0);
    }
  }

  openHelp() {
    if (!this.network.checkInternet()) {
      this.navCtrl.navigate(['/help']);
    }
    else {
      this.common.showHelpPage();
    }
  }


  ionViewDidLeave() {

    clearInterval(this.loadInterval);
    if (this.userSubs) {
      this.userSubs.unsubscribe();
      this.userSubs = null;
    }

    if (this.leadsSubs) {
      this.leadsSubs.unsubscribe();
      this.leadsSubs = null;
    }
  }


  openMySubscription() {
    this.common.openSubscription(environment.stripeSubscription + this.auth.getUserAuthToken());
  }


  async openLeadsDetail(lead) {

    const lookup = { email: '--', phone: '--' };

    if (this.lookupFormData.indexOf('@') > 0) {
      lookup.email = this.lookupFormData;
    }
    else {
      lookup.phone = this.lookupFormData;
    }

    const guestData = {
      fullName: lead.fullName,
      email: lookup.email,
      phoneNumber: lookup.phone,
      notes: '',
      verified: 'yes',
      source: 'manual lookup',
      avatarImg: lead.avatar || ''
    };

    const avatarName = this.auth.createAvatarName(guestData.fullName);

    const data = {
      avatarName,
      color: this.utils.avatarColor(avatarName),
      avatarImg: lead.avatar || '',
      listings: false,
      guest: guestData,
      fullContactGuestDetails: lead,
      questionsAnswers: []
    };

    console.log('guest prep is : ' + JSON.stringify(data));

    const modal = await this.modalCtrl.create({ component: MyLeadsDetailsComponent, componentProps: { data } });
    await modal.present();
    this.lookupFormData = '';

    const { } = await modal.onWillDismiss();
    this.loadLeads();
  }

  incrementLookups(count) {
    count++;
    this.auth.updateUserDataProperty({ totalLeadsLookups: count });
  }

  openMyProfile() {
    console.log('open my profile');
    this.navCtrl.navigate(['/my-profile']);
  }

  filterListingChange() {
    // Set default filter conditions
    let baseFilter = guest => true;

    console.log('filter listing change');
    // Update filter conditions based on selectedListing and showPartialLeads
    if (this.selectedListing && this.selectedListing !== '0') {
      if (this.showPartialLeads) {
        baseFilter = guest => this.selectedListing.includes(guest.listingId);
      } else {
        baseFilter = guest => this.selectedListing.includes(guest.listingId) && !guest.guest.partialLead;
      }
    } else {
      this.selectedListing = '0';
      if (!this.showPartialLeads) {
        baseFilter = guest => !guest.guest.partialLead;
      }
    }


    // Apply base filter
    this.finalRes = this.arr.filter(guest => {
      // Apply base filter
      let result = baseFilter(guest);

      // Further filter based on showMortgageLeads
      if (!this.showMortgageLeads) {
        result = result && !guest.isMask;
      }

      return result;
    });


    // Update finalResPaginated based on the filtered finalRes
    // if (!this.showMortgageLeads) {
    //   this.finalRes = this.arr.filter(guest => !guest.isMask);
    // }

    // Update numberOfGuest and call updateleadsTitle
    this.numberOfGuest = this.finalRes.length;
    this.updateleadsTitle();

    this.finalResPaginated = [];
    this.generateItems();
  }

  public share() {
    const data = this.finalRes;
    const me = this;
    if (!data) {
      return;
    }
    if (!this.network.checkInternet()) {
      this.network.noNetworkModal();
      return;
    }

    const objQues = [];
    const regex = new RegExp(',', 'g');
    data.forEach((el, i) => {
      el.questionsAnswers.forEach((element) => {
        const ques = element.question.replace(regex, '');
        console.log(ques);
        objQues.push(ques);
      });
    });
    const uniqueArray = objQues.filter((item, pos) => objQues.indexOf(item) === pos);
    let obj = { Name: '', Email: '', Notes: '' };

    function clearObj() {
      obj = { Name: '', Email: '', Notes: '' };
      uniqueArray.forEach((element, index) => {
        if (index === 0) {
          obj['Created At'] = '';
          obj['Listing Address'] = '';
          obj.Name = '';
          obj.Email = '';
          obj['Phone Number'] = '';
        }
        obj[element] = '';
        if (uniqueArray.length === index + 1) {
          obj.Notes = '';
        }
      });
    }

    clearObj();
    const itemArr = [];
    data.forEach((el) => {

      // offline partial leads do not have these fields so recreate
      if (!el.createdAt) {
        el.createdAt = this.common.formatDate(new Date(el.createdDate));
        el.time = this.common.formatAMPM(new Date(el.createdDate));
      }

      const address = (el.listings.address.title) ? el.listings.address.title.replace(regex, '') : el.listings.address.title;
      const time = (el.time) ? el.time : '';

      if (el.questionsAnswers.length === 0) {
        obj['Created At'] = el.createdAt + ' ' + time;
        obj['Listing Address'] = address;
        obj.Name = el.guest.fullName.replace(regex, '');
        obj.Email = el.guest.email;
        obj['Phone Number'] = this.common.formatPhone(el.guest.phoneNumber, el.guest.phoneCountryCode);
        obj.Notes = el.guest.notes.replace(regex, '');

        if (el.isMask) {
          obj.Name = '';
          obj.Email = '';
          obj['Phone Number'] = '';
        }


        itemArr.push(obj);
        clearObj();
      }
      el.questionsAnswers.forEach((element, index) => {
        if (index === 0) {
          obj['Created At'] = el.createdAt + ' ' + time;
          obj['Listing Address'] = address;
          obj.Name = el.guest.fullName.replace(regex, '');
          obj.Email = el.guest.email;
          obj['Phone Number'] = this.common.formatPhone(el.guest.phoneNumber, el.guest.phoneCountryCode);
        }

        if (el.isMask) {
          obj.Name = '';
          obj.Email = '';
          obj['Phone Number'] = '';
        }

        // Questions and answers output
        if (element.multipleChoiceOptions && element.multipleChoiceOptions.length > 0) {
          obj[element.question.replace(regex, '')] = this.getSelectedMultipleChoiceAnswerText(element).replace(regex, '');
        }
        else {
          obj[element.question.replace(regex, '')] = !isNaN(element.answer) ? element.answer : element.answer.replace(regex, '');
        }

        if (el.questionsAnswers.length === index + 1) {
          obj.Notes = el.guest.notes.replace(regex, '');
          itemArr.push(obj);
          clearObj();
        }


      });
    });

    const result = this.guestservice.JSONToCSVConvertor(itemArr, true);
    const blob = new Blob([result], { type: 'text/csv' });
    const fileName = 'Curb-Hero-Open-House-leads_' + new Date().getTime() + '.csv';

    if (this.plt.is('cordova')) {
      //  me.common.startLoading();
      me.file.createFile(me.file.dataDirectory, fileName, true).then(optFile => {
        me.file.writeExistingFile(me.file.dataDirectory, fileName, blob).then(writeFile => {
          // alert(JSON.stringify(writeFile)); optFile.nativeURL
          me.common.closeLoading();

          me.socialSharing.share('Curb Hero Open House leads', 'Curb Hero Open House leads', optFile.nativeURL, null)
            .then(() => {
            }).catch((err) => {
              console.log('error writing export file: ' + err);
              me.common.closeLoading();
              alert(err);
            });

        }).catch(err => {
          console.log('error writing export file2: ' + err);
          me.common.closeLoading();
          alert('write error' + err);
        });
      }).catch(err => {
        console.log('error writing export file3: ' + err);
        me.common.closeLoading();
        console.log(err);
      });
    }
    else { // WEB
      console.log('trying to export on the web');

      const csvURL = this.sanitizer.sanitize(SecurityContext.URL, this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob)));

      const tempLink = document.createElement('a');
      tempLink.href = csvURL;
      tempLink.setAttribute('download', fileName);
      tempLink.click();
    }

  }

  generateItems() {

    const count = this.finalResPaginated.length;

    console.log('infinite scroll called with ' + count);

    // alert('generate items ' + count);

    if (count >= this.finalRes.length) {
      return;
    }

    //  alert('first: ' + JSON.stringify(this.finalRes[0]));
    for (let i = count; i < count + this.paginationSize; i++) {
      if (this.finalRes[i]) {
        this.finalResPaginated.push(this.finalRes[i]);
      }
    }
  }

  onIonInfinite(ev) {

    console.log('onIonInfinite called');
    this.generateItems();
    setTimeout(() => {
      (ev as InfiniteScrollCustomEvent).target.complete();
    }, 500);
  }



  public async getMyleads(id) {
    console.log('getMyleads : ' + id);

    //  this.common.startLoading();
    this.leadsSubs = this.listings.data().subscribe({
      next: async (data) => {
        try {
          if (this.common.isDeepEqual(this.listingServiceData, data.guests)) {
            return;
          }

          this.listingServiceData = JSON.parse(JSON.stringify(data.guests));
          console.log('leadsSubs trigger');

          this.showRefresh = false;

          if (!data.guests || !data.listings) {
            console.log('there are no leads!');
            this.common.closeLoading();
            this.loaded = true;
            this.loading = false;
            this.showSkeleton = true;
            return;
          }

          const leads = data.guests as any[];
          const listings = data.listings as any[];
          this.arr = leads.filter(guest => !guest.archived);

          if (leads.length === 0) {
            console.log('leads length is zero');
            this.showSkeleton = true;
            this.loaded = true;
            this.loading = false;
          } else {
            this.totalGuestList = JSON.parse(JSON.stringify(leads));

            if (!this.network.checkInternet() || leads[0].fromCache) {
              this.offlineOrStaleData = true;
            } else {
              this.offlineOrStaleData = false;
              this.syncOfflineGuestsWithDB();
            }

            for (const guest of leads) {
              const gList = listings.find((listing) => listing.id === guest.listingId);
              if (gList) {
                guest.listings = gList;
              } else {
                guest.listings = { address: { title: '' } };

                if (User_global.role === 'agentAdmin' && guest.listingAddressTitle) {
                  guest.listings = { address: { title: guest.listingAddressTitle } };
                }
              }

              if (guest.guest && guest.guest.userId) {
                guest.listingAgent = await this.connectionsService.getConnectionDetails(guest.guest.userId);
              } else {
                guest.listingAgent = '';
              }
            }

            this.searchRes = this.getUnique(leads, 'listingId').filter((search) => search.listings && search.listings.address.title);
            this.showSkeleton = false;

            const tempGuests = leads.filter(guest => !guest.archived);
            this.sort('date');

            if (id != 0) {
              this.finalRes = tempGuests.filter(guest => (guest.listingId === id));
              this.selectedListing = id;
            } else {
              this.finalRes = tempGuests;

              if (this.finalRes.length === 0) {
                this.showSkeleton = true;
              }
            }

            this.finalRes.forEach(guest => {
              if ((!guest.guest.mortgageAnswer || guest.guest.mortgageAnswer === 'No') && User_global.role === 'lender') {
                guest.isMask = true;
              } else {
                guest.isMask = false;
              }
            });

            this.arr = JSON.parse(JSON.stringify(this.finalRes));
            this.filterListingChange();
            this.loading = false;
            this.initHorizontalScroll();

            setTimeout(() => {
              this.loaded = true;
            }, 3000);
          }

          this.common.closeLoading();

          setTimeout(() => {
            this.checkUrlRequest();
          }, 1000);

        } catch (ex) {
          console.log('error getting leads: ' + ex);
          this.showRefresh = true;
          this.common.closeLoading();
        }
      },
      error: (error) => {
        console.log('Error getting leads: ' + error);
        this.showRefresh = true;
        this.common.closeLoading();
      },
      complete: () => {
        console.log('Lead subscription completed.');
      }
    });

  }

  async openOfflineModal() {

    this.common.openOfflineModal();
  }

  // check to see if there is a web url request for specific guest
  // used by SMS notification deep link
  async checkUrlRequest() {

    const self = this;

    if (this.plt.is('cordova') || !this.network.checkInternet()) {
      return;
    }

    const url = String(window.location);
    const guestId = url.split('gst=')[1];
    let targetGuest;

    //console.log('searching all guests: ' + JSON.stringify(this.finalRes));
    if (this.finalRes && guestId) {

      self.common.startLoading();

      try {
        const res = await this.guestservice.getGuest(guestId);

        if (res) {
          targetGuest = res;
        } else {
          self.common.closeLoading();
          //   return;
        }
      } catch (err) {
        self.common.closeLoading();
        //   return;
      }


      if (!targetGuest) {

        // Get the current URL
        const currentUrl = window.location.href;
        // Find the position of the question mark
        const indexOfQuery = currentUrl.indexOf('?');
        // Check if there is a query string present
        if (indexOfQuery !== -1) {
          // Remove the query string by getting the substring up to the question mark
          const newUrl = currentUrl.substring(0, indexOfQuery);
          // Update the URL without reloading the page
          window.history.replaceState(null, '', newUrl);
        }

        self.common.closeLoading();
        alert(this.i8nService.messages.lenderLeadGuestDoesntExistOnAccount);
        return;
      }

      console.log('targetGuest: ' + JSON.stringify(targetGuest));

      const targetListing = await this.listings.listing(targetGuest.listingId);

      // alert(JSON.stringify(targetListing));

      targetGuest.listings = targetListing;
      if (targetListing && targetGuest.guest.userId) {
        targetGuest.listingAgent = await this.connectionsService.getConnectionDetails(targetGuest.guest.userId);
      }
      else {
        targetGuest.listingAgent = '';
      }

      let guestAvatar = targetGuest.guest.avatar || '';
      if (targetGuest.fullContactGuestDetails && targetGuest.fullContactGuestDetails.avatar) {
        guestAvatar = targetGuest.fullContactGuestDetails.avatar;
      }

      targetGuest.avatarName = this.auth.createAvatarName(targetGuest.guest.fullName) || '';
      targetGuest.signInTime = this.auth.createAvatarName(targetGuest.guest.fullName) || '';
      targetGuest.color = this.utils.avatarColor(targetGuest.avatarName);

      targetGuest.signInTime = new Date(Number(targetGuest.createdDate));
      targetGuest.avatarImg = guestAvatar;

      if (targetGuest) {
        setTimeout(() => {

          self.common.closeLoading();

          this.onGuestClick(targetGuest);
        }, 2000);
        window.history.pushState(guestId, 'BlockParty', '/' + window.location.href.substring(window.location.href.lastIndexOf('/') + 1).split('?')[0]);
      }
      else {
        self.common.closeLoading();
        alert(this.i8nService.messages.lenderLeadGuestDoesntExistOnAccount);
        window.history.pushState(guestId, 'BlockParty', '/' + window.location.href.substring(window.location.href.lastIndexOf('/') + 1).split('?')[0]);
      }
    }
  }

  updateleadsTitle() {
    if (this.numberOfGuest === 1) {
      this.leadsTitle = `${this.numberOfGuest} Lead`;
    } else {
      this.leadsTitle = `${this.numberOfGuest} Leads`;
    }
  }

  async openFilter(event) {
    event.stopPropagation();
    //   this.filterSelect.open();
    const self = this;

    const filterModal = await self.modalCtrl.create({
      component: FilterLeadsPopupComponent,
      componentProps: {
        data: self.searchRes,
        selectedListing: this.selectedListing || 0,
        showPartialLeads: this.showPartialLeads,
        showMortgageLeads: this.showMortgageLeads
      }
    });

    await filterModal.present();
    const { data } = await filterModal.onWillDismiss();

    if (data === undefined || !data) {
      console.log('data is empty');
      return;
    }

    this.selectedListing = data.selectedListing;
    this.showPartialLeads = data.partialLeads;
    this.showMortgageLeads = data.mortgageLeads;

    console.log('partial Leads: ' + data.partialLeads);
    console.log('mortgage Leads: ' + data.mortgageLeads);
    console.log('selected listing: ' + this.selectedListing);
    this.filterListingChange();

  }

  async onGuestClick(info) {
    console.log('onGuestClick for ' + JSON.stringify(info));
    const modal = await this.modalCtrl.create({ component: MyLeadsDetailsComponent, componentProps: { data: info } });

    await modal.present();

    const { data } = await modal.onWillDismiss();

    console.log('dismissing modal ' + data);
    if (data === undefined || data === null) {
      return;
    }
    if (data === 'archived') {
      //  this.ngOnInit();
    }
  }


  getUnique(arr, comp) {
    const unique = arr.map((e) => e[comp]).map((e, i, final) => final.indexOf(e) === i && i).filter((e) => arr[e]).map((e) => arr[e]);
    return unique;
  }

  defaultClass() {
    if (document.getElementById('sortaddress')) { document.getElementById('sortaddress').className = 'sort-by'; }
    if (document.getElementById('sortname')) { document.getElementById('sortname').className = 'sort-by'; }
    if (document.getElementById('sortnotes')) { document.getElementById('sortnotes').className = 'sort-by'; }
    if (document.getElementById('sortdate')) { document.getElementById('sortdate').className = 'sort-by'; }
  }

  sort(fieldName) {
    if (fieldName === this.currentSortColumn) {
      this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
    } else {
      this.currentSortColumn = fieldName;
      this.currentSortDir = 'asc';
    }
    const data = this.finalResPaginated;
    if (!data) {
      return;
    }

    if (fieldName === 'address') {
      data.sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === 'desc') { modifier = -1; }
        if (a.listings.address.title < b.listings.address.title) { return -1 * modifier; }
        if (a.listings.address.title > b.listings.address.title) { return 1 * modifier; }
        return 0;
      });
    }
    if (fieldName === 'name') {
      data.sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === 'desc') { modifier = -1; }
        if (a.guest.fullName < b.guest.fullName) { return -1 * modifier; }
        if (a.guest.fullName > b.guest.fullName) { return 1 * modifier; }
        return 0;
      });
    }
    if (fieldName === 'phone') {
      data.sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === 'desc') { modifier = -1; }
        if (a.guest.phoneNumber < b.guest.phoneNumber) { return -1 * modifier; }
        if (a.guest.phoneNumber > b.guest.phoneNumber) { return 1 * modifier; }
        return 0;
      });
    }
    if (fieldName === 'notes') {
      data.sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === 'desc') { modifier = -1; }
        if (a.guest.notes < b.guest.notes) { return -1 * modifier; }
        if (a.guest.notes > b.guest.notes) { return 1 * modifier; }
        return 0;
      });
    }
    if (fieldName === 'email') {
      data.sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === 'desc') { modifier = -1; }
        if (a.guest.email < b.guest.email) { return -1 * modifier; }
        if (a.guest.email > b.guest.email) { return 1 * modifier; }
        return 0;
      });
    }
    if (fieldName === 'date') {
      data.sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === 'desc') { modifier = -1; }
        if (a.createdDate < b.createdDate) { return -1 * modifier; }
        if (a.createdDate > b.createdDate) { return 1 * modifier; }
        return 0;
      });
    }
    if (fieldName === 'source') {
      data.sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === 'desc') { modifier = -1; }
        if (a.guest.source < b.guest.source) { return -1 * modifier; }
        if (a.guest.source > b.guest.source) { return 1 * modifier; }
        return 0;
      });
    }
  }

  getStarted() {
    if (this.userRole === 'agent') {
      this.navCtrl.navigate(['/my-listings']);
    }
    else if (this.userRole === 'lender') {
      this.navCtrl.navigate(['/my-paired-agents']);
    }
    else if (this.userRole === 'agentAdmin') {
      //agentAdmin
      this.navCtrl.navigate(['/my-paired-agents']);
    }

  }

  learnMore() {
    if (User_global.role === 'agentAdmin') {
      this.common.openLink('https://juuj.me/how-does-agent-admin-pairing-work');
    }
    else {
      this.common.openLink('https://juuj.me/creating-and-managing-a-lender-subscription');
    }
  }

  refreshPage() {
    this.common.reloadPage();
  }

  // getSelectedMultipleChoiceAnswerText(question: QuestionsAnswers) {
  //   const defaultMissing = 'N/A';

  //   if (question.multipleChoiceOptions == null || question.multipleChoiceOptions.length === 0) {
  //     return defaultMissing;
  //   }

  //   const selectedAnswer = question.multipleChoiceOptions.find(o => o.isSelected);
  //   return (selectedAnswer) ? selectedAnswer.value : defaultMissing;
  // }


  getSelectedMultipleChoiceAnswerText(question: QuestionsAnswers) {
    const defaultMissing = 'N/A';

    if (question.multipleChoiceOptions == null || question.multipleChoiceOptions.length === 0) {
      return defaultMissing;
    }

    const selectedAnswer = question.multipleChoiceOptions.find(o => o.isSelected);

    if (selectedAnswer && !selectedAnswer.details?.inputValue) {
      return (selectedAnswer) ? selectedAnswer.value : defaultMissing;
    }

    return (selectedAnswer) ? selectedAnswer.value + ': ' + selectedAnswer.details?.inputValue : defaultMissing;
  }

  async leadPartialInfo() {
    const myAlert = await this.alertCtrl.create({
      header: 'Partial Entries',
      message: this.i8nService.messages.partialEntriesAlert,
      buttons: [
        {
          role: 'cancel',
          text: 'OK',
          handler: data => {
          }
        }
      ]
    });

    await myAlert.present();
  }

  async syncOfflineGuestsWithDB() {
    if (User_global.role === 'lender') return;
    if (User_global.role === 'agentAdmin') return;

    // Don't sync offline guests if no internet or stale data
    if (!this.network.checkInternet() || this.offlineOrStaleData) return;
    if (this.offlineSyncRunning) {
      console.log('offline sync is still running!');
      return;
    }

    try {
      this.offlineSyncRunning = true;

      const offlineGuestsArray = this.plt.is('cordova')
        ? await this.getOfflineGuestsFromSQL()
        : this.getOfflineGuestsFromLocalStorage();

      if (!offlineGuestsArray || offlineGuestsArray.length === 0) return;

      console.log('sync offline guests called');
      const synced = await this.processOfflineGuests(offlineGuestsArray);

      this.offlineSyncRunning = false;

      if (synced) {
        setTimeout(() => this.loadLeads(), 2000);
      }

    } catch (err) {
      console.log('error in my leads:', err);
      this.offlineSyncRunning = false;
      this.auth.logErrors(`Error syncing offline guest: ${err}`, true);
    } finally {
      //
    }
  }

  // Extracted method for retrieving guests from SQL
  private async getOfflineGuestsFromSQL(): Promise<any[]> {
    try {
      return await this.sql.getGuestData();
    } catch (error) {
      console.log('Error retrieving guests from SQL:', error);
      throw error;
    }
  }

  // Extracted method for retrieving guests from local storage
  private getOfflineGuestsFromLocalStorage(): any[] {
    const arrayOfKeys = Object.keys(localStorage);
    const myUID = this.auth.getUID();
    const offlineGuestsArray = [];

    for (const key of arrayOfKeys) {
      if (key.startsWith('guest_')) {
        const obj = JSON.parse(localStorage.getItem(key));
        obj.guestId = key.split('guest_')[1];

        if (this.isValidGuest(obj, myUID)) {
          offlineGuestsArray.push(obj);
        }

        // Limit to 500 guests
        if (offlineGuestsArray.length > 500) break;
      }
    }

    console.log('full array of guests:', offlineGuestsArray);
    return offlineGuestsArray;
  }

  private deleteGuestFromLocalStorage(guestId: string): void {
    try {
      const key = `guest_${guestId}`;
      localStorage.removeItem(key);
      console.log(`Guest with ID ${guestId} removed from localStorage.`);
    } catch (error) {
      console.error(`Error removing guest with ID ${guestId} from localStorage:`, error);
    }
  }


  // Method to validate guest UID
  private isValidGuest(guestData: any, myUID: string): boolean {
    const hasContactInfo = guestData.guest.phoneNumber || guestData.guest.email || guestData.guest.fullName;

    return (guestData.guest.userId === myUID || !guestData.guest.userId) && hasContactInfo;
  }

  // Process the offline guests

  private async processOfflineGuests(offlineGuestsArray: any[]): Promise<boolean> {
    let writeCounter = 1;

    if (!offlineGuestsArray.length || !this.totalGuestList.length) {
      console.log('one of the guest arrays is empty');
      return false; // No guests to process, return false
    }

    // Optional: Use a Map for efficient lookup if totalGuestList is large
    const guestLookup = new Map(this.totalGuestList.map(guest =>
      [guest.guest.email + guest.guest.fullName + guest.guest.phoneNumber + guest.listingId, guest]
    ));

    for (let i = 0; i < offlineGuestsArray.length && i < 500; i++) {
      const offlineGuest = offlineGuestsArray[i];
      const guestExists = guestLookup.has(
        offlineGuest.guest.email + offlineGuest.guest.fullName + offlineGuest.guest.phoneNumber + offlineGuest.listingId
      );

      if (!guestExists) {
        console.log('writing offline guest to db! ' + offlineGuest.guest.email);
        offlineGuest.guest.offlineSync = true;
        setTimeout(() => this.writeGuestDataDB(offlineGuest), writeCounter * 500);
        writeCounter++; // Increment counter when a guest is written
      }

    }

    // Return true if at least one guest was written to the database
    return writeCounter > 1;
  }



  async writeGuestDataDB(guestSurveyData: any) {
    try {
      if (!guestSurveyData) {
        console.log('my leads Warning! The Guest information is empty.');
        return;
      }

      const { fullName, email, phoneNumber } = guestSurveyData.guest || {};

      if (!fullName && !email && !phoneNumber) {
        console.log('my leads Warning! The Guest information is empty.');
        return;
      }

      // Convert string booleans to actual booleans
      guestSurveyData.archived = guestSurveyData.archived === 'true' || guestSurveyData.archived === true;

      // Remove unnecessary fields
      if (guestSurveyData.createdDateReadable) {
        delete guestSurveyData.createdDateReadable;
      }

      try {
        const res = await this.listings.submitGuestSurvey(
          guestSurveyData,
          guestSurveyData.guestId,
          !this.network.checkInternet()
        );

        if (res) {
          console.log('Offline guest submitted!', res);
        } else {
          this.logSyncError('Error syncing offline guest', guestSurveyData);
        }
      } catch (error) {
        this.logSyncError('Error syncing offline guest', guestSurveyData, error);
      }
    } catch (err) {
      this.logSyncError('Offline sync Error with submitting guest', guestSurveyData, err);
    }
  }

  private logSyncError(message: string, data: any, error?: any) {
    console.log(`${message}: ${error ? error : ''}`);
    this.auth.logErrors(`${message}: ${JSON.stringify(data)} ${error ? 'err:' + error : ''}`, true);
  }

}
